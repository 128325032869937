import React, {useLayoutEffect} from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { useStore } from "../store"
//import {getViewPortSize} from '../utilities'
//import closeIcon from '../icons/icon_close_64.png'


function MyDialog(props) {
	Modal.setAppElement('#modalanchor')

	const dialogOpen = useStore(state => state.dialogOpen)
	const setDialogOpen = useStore(state => state.setDialogOpen)
	const closeDialog = () => setDialogOpen(false)


	const classes = 'modal' + (props.sizeClass ? (' ' + props.sizeClass) : '')

	return (
		<Modal {...props}
			className={classes}
			overlayClassName='modal-overlay'
			isOpen={dialogOpen}
			closeTimeoutMS={400}
			onRequestClose={closeDialog}
		>
			{props.children}
			<div className="dialog-footer text-center">
				<button className="btn filled rounded" onClick={closeDialog}>OK</button>
			</div>
		</Modal>
	)
}


MyDialog.propTypes = {
	sizeClass: PropTypes.string,
	children: PropTypes.any
}
export default MyDialog
