import React, { Suspense, useRef, useState } from 'react'
import { Canvas, extend, useThree, useLoader, useFrame } from 'react-three-fiber'
import {HTML} from 'drei'
import {ACESFilmicToneMapping, sRGBEncoding} from 'three'
import Controls from './Controls'
import DeviceOrientationControls from './DeviceOrientationControls'
import ImagePanorama from './ImagePanorama'
import Hotspots from './Hotspots'
import { useStore } from "../store"
import './styles/VRViewer.scss'

function Fallback() {
	return(
		<HTML>
			<div className="loading">Loading...</div>
		</HTML>
	)
}


function VRViewer() {
	const hasDeviceOrientation = useStore(state => state.hasDeviceOrientation)

	return(
		<Canvas
			// sRGB
			gl={{ alpha: false }}
			camera={{ position: [0, 0, 3], near:0.1, far:600, fov: 75}}
			onCreated={({ gl }) => {
				gl.setClearColor("#f4f7ff")
				//gl.toneMapping = ACESFilmicToneMapping
				//gl.toneMapping = THREE.Uncharted2ToneMapping
				//gl.outputEncoding = sRGBEncoding
			}}
		>
			<Controls
				enableZoom={false}
				enablePan={false}
				enableDamping={true}
				dampingFactor={0.2}
				enableKeys={true}
				keyPanSpeed={7.0}
				maxDistance={400}
				minDistance={1}
				minPolarAngle={Math.PI / 6}
				maxPolarAngle={Math.PI / 1.3}
			/>
			{hasDeviceOrientation ?
				<DeviceOrientationControls /> : null}

			<group>
				<Suspense fallback={<Fallback />}>
					<ImagePanorama />
					<Hotspots />
				</Suspense>
			</group>
	</Canvas>
	)
}

export default VRViewer
