import React, { Component } from "react"
import PropTypes from "prop-types"
import fscreen from "fscreen"

// ----------------------------------------------------------
// Based on react-full-screen.
// Uses body tag as fullscreen element to include the
// modal dialogs in fullscreen.
// ----------------------------------------------------------

class MyFullScreen extends Component {
	static propTypes = {
		children: PropTypes.node.isRequired,
		enabled: PropTypes.bool.isRequired,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		enabled: false,
	};

	constructor(props) {
		super(props)

		this.detectFullScreen = this.detectFullScreen.bind(this)
		//this.handleKeyEvent = this.handleKeyEvent.bind(this)
		this.fsNode = document.getElementsByTagName('body')[0]
	}

	componentDidMount() {
		fscreen.addEventListener("fullscreenchange", this.detectFullScreen)
	}

	componentWillUnmount() {
		fscreen.removeEventListener("fullscreenchange", this.detectFullScreen)
	}

	componentDidUpdate() {
		this.handleProps(this.props)
	}

	handleProps(props) {
		const enabled = fscreen.fullscreenElement === this.fsNode
		if (enabled && !props.enabled) {
			this.leaveFullScreen()
		} else if (!enabled && props.enabled) {
			this.enterFullScreen()
		}
	}

	detectFullScreen() {
		if (this.props.onChange) {
			this.props.onChange(fscreen.fullscreenElement === this.fsNode)
		}
	}

	enterFullScreen() {
		if (fscreen.fullscreenEnabled) {
			//this.fsNode.addEventListener('keydown', this.handleKeyEvent)
			fscreen.requestFullscreen(this.fsNode)
		}
	}

	// handleKeyEvent(evt) {
	// 	console.log(evt.code)
	// 	if (evt.code === 'Escape') { // ESC key
	// 		console.log("is ESC")
	// 		evt.preventDefault()
	// 		evt.cancelBubble = true
	// 		this.leaveFullScreen()
	// 	}
	// }

	leaveFullScreen() {
		if (fscreen.fullscreenEnabled) {
			//this.fsNode.removeEventListener('keydown', this.handleKeyEvent)
			fscreen.exitFullscreen()
		}
	}

	render() {
		const className = ["fullscreen"]
		if (this.props.enabled) {
			className.push("fullscreen-enabled")
		}

		return (
			<div
				className={className.join(" ")}
				// ref={node => (this.node = node)}
				style={
					this.props.enabled ? { height: "100%", width: "100%" } : undefined
				}
			>
				{this.props.children}
			</div>
		)
	}
}

export default MyFullScreen
