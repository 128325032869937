import React, { useRef } from "react"
import { extend, useFrame, useThree } from "react-three-fiber"
//import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { DeviceOrientationControls as DvcOrientationCtrls} from "../lib/DeviceOrientationControls"

extend({ DvcOrientationCtrls })

const DeviceOrientationControls = props => {
  const deviceControlsRef = useRef()
	const { camera, gl } = useThree()
	
	useFrame(() => {
		deviceControlsRef.current && deviceControlsRef.current.update()
	})
	
  return <dvcOrientationCtrls ref={deviceControlsRef} args={[camera, gl.domElement]} {...props} />
}

export default DeviceOrientationControls
