import React from 'react'
//import ReactPlayer from 'react-player'


function AstTalTicket() {
	return(
		<div className="panel-body">
			<h1>Foto der Konzertkarte „TAL“</h1>
			<img src="./assets/images/Tal_Ticket.jpg" className="img-responsive" alt="Foto der Konzertkarte „TAL“" />
			<p className="reference">Quelle: © Ernst Klett Verlag GmbH, Stuttgart 2020, Fotograf: Cécile Quirot</p>
		</div>
	)
}

export default AstTalTicket
