import React from 'react'
//import Modal from 'react-modal'
import MyModal from '../components/MyModal'
import { useStore } from "../store"

import AstScene1Info from "./AstScene1Info"
import AstTalText from "./AstTalText"
import AstTalTicket from "./AstTalTicket"
import AstTaschePacken from "./AstTaschePacken"
import AstBuecherRegal from "./AstBuecherRegal"
import AstMaxZimmer from "./AstMaxZimmer"
import AstChat from "./AstChat"

function AssetViewer() {
	const currentAsset = useStore(state => state.currentAsset)
	
	const afterOpenModal = () => {}
	let modalSize = null

	const getAssetContent = () => {
		let theContent
		switch(currentAsset) {
			case "astScene1Info":
				theContent = <AstScene1Info />
				modalSize = MyModal.sizeClasses.medium
				break
			case "astTalText":
				theContent = <AstTalText />
				break
			case "astTalTicket":
				theContent = <AstTalTicket />
				modalSize = MyModal.sizeClasses.landscapeW80
				break
			case "astTaschePacken":
				theContent = <AstTaschePacken />
				break
			case "astBuecherRegal":
				theContent = <AstBuecherRegal />
				modalSize = MyModal.sizeClasses.landscapeW70
				break
			case "astMaxZimmer":
				theContent = <AstMaxZimmer />
				modalSize = MyModal.sizeClasses.landscapeW65
				break
			case "astChat":
				theContent = <AstChat />
				modalSize = MyModal.sizeClasses.landscapeW35
				break

			default:
				theContent = (
				<>
					<h2>Das ist das Asset <small className="label">40px</small></h2>
					<i className="icon icon-menu"></i>
					<i className="icon icon-arrow-right"></i>
					<i className="icon icon-mail"></i>
				</>
				)
		}
		return theContent
	}

	const assetContent = currentAsset && getAssetContent() // must be called here to have the size class in place

	return (
		<MyModal
			onAfterOpen={afterOpenModal}
			sizeClass={modalSize}
		>
			{assetContent}
		</MyModal>
	)
}

export default AssetViewer
