import React from 'react'
import { useStore } from "../store"
import KlettIcon from './KlettIcon'

function MainToolbar() {
	const isFullscreen = useStore(state => state.isFullscreen)
	const setIsFullscreen = useStore(state => state.setIsFullscreen)
	const switchFs = () => setIsFullscreen(!isFullscreen)

	const setCurrentAsset = useStore(state => state.setCurrentAsset)
	const setAssetModalOpen = useStore(state => state.setAssetModalOpen)
	const curInfo = useStore(state => state.scenes[state.currentScene].sceneInfo)

	const handleInfoClick = () => {
		setCurrentAsset(curInfo)
		setAssetModalOpen(true)
	}

	return(
		<div className="main-toolbar">
			<button className="btn ui-btn" onClick={handleInfoClick}><KlettIcon icon={'INFO'}/></button>
			<button className="btn ui-btn" onClick={switchFs}><KlettIcon icon={isFullscreen ? 'FS_CONTRACT' : 'FS_EXPAND'}/></button>
		</div>
	)
}

export default MainToolbar
