import React, {useLayoutEffect} from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { useStore } from "../store"
//import {getViewPortSize} from '../utilities'
//import closeIcon from '../icons/icon_close_64.png'


function MyModal(props) {
	Modal.setAppElement('#modalanchor')

	const assetModalOpen = useStore(state => state.assetModalOpen)
	const setAssetModalOpen = useStore(state => state.setAssetModalOpen)
	const closeModal = () => setAssetModalOpen(false)

	/*const handleResize = () => {
		const el = document.getElementById('modal-container')
		//console.log(el)
		if (el) {
			const {vw, vh} = getViewPortSize()
			const w = el.offsetWidth
			const h = el.offsetHeight
			//console.log(h, vh)
			if (h > vh) {
				const aspect = w / h
				const newH = vh - 120
				//console.log(aspect)
				el.setAttribute('style', `height: ${newH}px; width: ${Math.floor((newH * aspect) - 40)}px;`) 
			}
		}
	}*/

	/*useLayoutEffect(() => {
		setTimeout(() => {
			handleResize()
		}, 50)
		window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
	})*/

	let classes = 'modal' + (props.sizeClass ? (' ' + props.sizeClass) : '')

	return (
		<Modal {...props}
			className={classes}
			overlayClassName='modal-overlay'
			isOpen={assetModalOpen}
			closeTimeoutMS={400}
			onRequestClose={closeModal}
		>
			<button className="btn icon-btn icon-close" onClick={closeModal}></button>
			<div className="panel">
				{props.children}
			</div>
		</Modal>
	)
}


MyModal.sizeClasses = {
	small: "small",
	medium: "medium",
	large: "large",
	landscapeW35: "landscape-w35",
	landscapeW40: "landscape-w40",
	landscapeW45: "landscape-w45",
	landscapeW50: "landscape-w50",
	landscapeW55: "landscape-w55",
	landscapeW60: "landscape-w60",
	landscapeW65: "landscape-w65",
	landscapeW70: "landscape-w70",
	landscapeW75: "landscape-w75",
	landscapeW80: "landscape-w80"
}

MyModal.propTypes = {
	sizeClass: PropTypes.string,
	children: PropTypes.any
}
export default MyModal
