import React from 'react'


function AstScene1Info() {
	return(
		<div className="panel-body">
			<h2>360°-Foto</h2>
			<img src="./assets/images/Lola_Schreibtisch_splash.jpg" className="img-responsive" alt="Lola am Schreibtisch" />
			<p className="reference">Quelle: © Ernst Klett Verlag GmbH, Stuttgart 2020, Fotograf: Johannes Killinger</p>
		</div>
	)
}

export default AstScene1Info
