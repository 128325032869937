import React from 'react'
import {TextureLoader, BackSide, LinearFilter} from 'three'
import {useLoader } from "react-three-fiber"
import { useStore } from "../store"

function ImagePanorama () {

	/*constructor(props) {
		super(props)
		this.state = {
			panoImgSrc: props.panoSrc,
			panoTexture: null,
			rotation: props.rotation ? props.rotation : [0,1.5707963,0] // rotate 90 Deg. to aim at the center of the panorama
		}
		
	}

	componentDidMount() {
		loadPanoImage(this.state.panoImgSrc, this.state.rotation)
	}

	const loadPanoImage = (imgSrc) => {
		const loader = new TextureLoader()
		const texture = loader.load(imgSrc)
		texture.magFilter = LinearFilter
		texture.minFilter = LinearFilter
		this.setState({
			panoImgSrc: imgSrc,
			panoTexture: texture
		})
	}*/

	const sceneData = useStore(state => state.scenes[state.currentScene])
	const texture = useLoader(TextureLoader, sceneData.panoramaSrc)
	// const loader = new TextureLoader()
	// const texture = loader.load(sceneData.panoramaSrc)
	texture.magFilter = LinearFilter
	texture.minFilter = LinearFilter

	return (
		<mesh rotation={sceneData.panoramaRotation || [0, 1.5707963, 0]} scale={[1, 1, -1]}>
			<sphereBufferGeometry attach="geometry" args={[60, 60, 40]} />
			<meshBasicMaterial attach="material" map={texture} side={BackSide} />
		</mesh>
	)

}

export default ImagePanorama
