import React from 'react'
import AudioPlayer from '../components/AudioPlayer'


function AstChat() {
	return(
		<div className="panel-body">
			<h1>Un message est arrivé</h1>
			<img src="./assets/images/Chatverlauf.jpg" className="img-responsive" alt="Lola am Bücherregal" />
			<AudioPlayer url="./assets/audio/Chat_audio.mp3" />
			<p className="reference">Quelle: © Ernst Klett Verlag GmbH, Stuttgart 2020</p>
		</div>
	)
}

export default AstChat
