import React, { useRef } from "react"
import { extend, useFrame, useThree } from "react-three-fiber"
//import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { OrbitControls } from "../lib/OrbitControls"

extend({ OrbitControls })

const Controls = props => {
  const controlsRef = useRef()
  const { camera, gl } = useThree()
	useFrame(() => {
		controlsRef.current && controlsRef.current.update()
	})
	
  return <orbitControls ref={controlsRef} args={[camera, gl.domElement]} {...props} />
}

export default Controls
