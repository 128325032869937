import React, {useState} from "react"
import Hotspot from './Hotspot'
import { useStore } from "../store"
import {HotspotActions} from '../constants'


function Hotspots() {
	//const [myVal, setValue] = useState("Anfangswert")
	const setScene = useStore(state => state.setScene)
	const hotspotsData = useStore(state => state.scenes[state.currentScene].hotspots)
	const setCurrentAsset = useStore(state => state.setCurrentAsset)
	const setAssetModalOpen = useStore(state => state.setAssetModalOpen)
	
	return (
		hotspotsData.map((data) => {
			let action = null
			switch(data.action) {
				case HotspotActions.setScene:
					action = () => setScene(data.target)
					break
				case HotspotActions.showAsset:
					action = () => {
						setCurrentAsset(data.target)
						setAssetModalOpen(true)
					}
					break
				default:
					action = () => console.log("Hotspot without action")
			}
			return (
				<Hotspot position={data.position} onClick={action} color='lightblue' icon={data.icon} key={data.target} />
			)
		})
	)
}

export default Hotspots
