
const getViewPortSize = () => {
	const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
	const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
	return {
		vw,
		vh
	}
}

const requestDeviceOrientation = async () => {
	if (typeof DeviceMotionEvent.requestPermission === "function") {
		//alert("Hello")
		const permissionState = await DeviceOrientationEvent.requestPermission()
		if (permissionState === "granted") {
				// Permission granted
				return true
		} else {
				// Permission denied
				return false
		}
	} else {
		return isTouchDevice()
	}
}

/*const requestDeviceOrientation = () => {
	if (typeof DeviceMotionEvent.requestPermission === "function") {
		//alert("Hello")
		DeviceOrientationEvent.requestPermission()
		.then(response => {
			if (response == 'granted') {
				//alert("permission granted")
				// window.addEventListener('deviceorientation', (evt) => {
				// 	// do something with e
				// 	// console.log(evt)
				// 	console.log("motion")
				// })
				return true
			} else {
				return false
			}
		})
		// .catch(console.error)
		.catch((err) => {
			console.error(err)
			return isTouchDevice()
		})
	} else {
		return isTouchDevice()
	}
}*/

const isTouchDevice = () => {
	try {
		const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
		const mq = function (query) {
			return window.matchMedia(query).matches
		}

		if (('ontouchstart' in window) || (typeof window.DocumentTouch !== "undefined" && document instanceof window.DocumentTouch)) {
			return true
		}

		// include the 'heartz' as a way to have a non matching MQ to help terminate the join
		// https://git.io/vznFH
		const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
		return mq(query)
	} catch (e) {
		console.error('Touch detection failed.', e)
		return false
	}
}

export {
	getViewPortSize,
	requestDeviceOrientation,
	isTouchDevice
}
