
const HotspotActions = {
	setScene: "setScene",
	showAsset: "showAsset"
}

const HostspotDistance = 50
const HostspotBaseScale = 4.5

const IconTypes = {
	image: "image",
	audio: "audio",
	text: "text",
	doubleArrowUp: "doubleArrowUp"
}

export {
	HotspotActions,
	HostspotDistance,
	HostspotBaseScale,
	IconTypes
}