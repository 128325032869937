import React from 'react'
import PropTypes from 'prop-types'
import Plyr from '@rocketseat/react-plyr'
import 'plyr/dist/plyr.css'
import './styles/PlyrOverrides.scss'

function AudioPlayer(props) {
	const playerControls = ['play', 'progress', 'current-time', 'mute', 'volume']
	return (
		<Plyr provider="audio" url={props.url} controls={playerControls} hideControls={false} resetOnEnd={true} playsinline storage={{enabled: false}} />
	)
}

AudioPlayer.propTypes = {
	url: PropTypes.string
}

export default AudioPlayer
