import create from "zustand"
import {HotspotActions, IconTypes} from './constants'
import {isTouchDevice} from './utilities'


const [useStore] = create(set => ({
	currentScene: "scene1", // scene to start with
	currentAsset: null,
	setCurrentAsset: (assetId) => {
		set({currentAsset: assetId})
	},
	assetModalOpen: false,
	setAssetModalOpen: (isOpen) => {
		set({assetModalOpen: isOpen})
	},
	dialogOpen: false,
	setDialogOpen: (isOpen) => {
		set({dialogOpen: isOpen})
	},
	dialogContent: null, // content for the dialog
	setDialogContent: (contentId) => {
		set({dialogContent: contentId})
	},
	isFullscreen: false,
	setIsFullscreen: (isFs) => {
		set({isFullscreen: isFs})
	},
	isTouchDevice: isTouchDevice(),
	hasDeviceOrientation: false,
	setHasDeviceOrientation: (hasDevOr) => {
		set({hasDeviceOrientation: hasDevOr})
	},
  scenes: {
		"scene1": {
			panoramaSrc: './assets/panoramas/Lola_Schreibtisch.jpg',
			panoramaRotation: [0, -.22, 0], // 90 degrees
			sceneInfo: 'astScene1Info',
			hotspots: [
				{
					position: [-2.559614501557247, 10.395584540887965, -48.84035417210515],
					icon: IconTypes.text,
					action: HotspotActions.showAsset,
					target: "astTalText"
				},
				{
					position: [-0.4346664198094761, -4.357787137382909, -49.807838302507676],
					icon: IconTypes.image,
					action: HotspotActions.showAsset,
					target: "astTalTicket"
				},
				{
					position: [0.7971783312856419, -20.336832153790006, -45.670316012227254],
					icon: IconTypes.image,
					action: HotspotActions.showAsset,
					target: "astTaschePacken"
				},
				{
					position: [46.95600927154852, 1.7449748351250485, -17.09058969477149],
					icon: IconTypes.audio,
					action: HotspotActions.showAsset,
					target: "astBuecherRegal"
				},
				{
					position: [-48.03171917730849, -12.940952255126037, -5.048337112626712],
					icon: IconTypes.audio,
					action: HotspotActions.showAsset,
					target: "astMaxZimmer"
				},
				// {
				// 	position: [-49.53687196510138, 4.357787137382909, -5.206535045345684],
				// 	icon: IconTypes.doubleArrowUp,
				// 	action: HotspotActions.setScene,
				// 	target: "scene2"
				// },
				{
					position: [4.774575140626326, -20.336832153790006, 45.427048001989796],
					icon: IconTypes.audio,
					action: HotspotActions.showAsset,
					target: "astChat"
				},
			]
		},

		"scene2": {
			panoramaSrc: './assets/panoramas/Flur_Zimmer_zu_Max.jpg',
			panoramaRotation: [0, 2.84, 0], // 90 degrees = 1.5707963
			sceneInfo: 'astScene2Info',
			hotspots: [
				{
					position: [-48.28893555535789, -0.8726203218641756, 12.938981285416666],
					icon: IconTypes.doubleArrowUp,
					action: HotspotActions.setScene,
					target: "scene1"
				},
				{
					position: [-37.16448246479101, -12.096094779983387, -31.184703529100616],
					icon: IconTypes.audio,
					action: HotspotActions.showAsset,
					target: "astMaxZimmer"
				},
			]
		}
	},
	setScene: (sceneId) => {
		set({currentScene: sceneId})
	}
}))

export { useStore }
