import React from 'react'

function AstTalText() {
	return(
		<div className="panel-body">
			<h1>TAL</h1>
			<h2>Chanteuse, musicienne et actrice</h2>
			<p>
				<strong>Née:</strong> le 12 décembre 1989, Israël<br/>
				<strong>Style de musique:</strong> variété française, pop, soul<br/>
				<strong>Instruments:</strong> Voix, piano, guitare
			</p>
			<p>
			Plus d’1.5 million d’unités vendus, 3 NRJ Music Awards, 1 World Music Award, 500 millions de vues sur les chaînes du web (le record pour une artiste française), 4.5 millions de fans et followers sur les réseaux sociaux … La liste des chiffres qui accompagne généralement le nom de TAL a de quoi donner le vertige, surtout quand on sait, qu’il y a quelques années à peine, la jeune fille s’accompagnait à la guitare dans de modestes clubs parisiens.
			</p>
			<p>
			Tal est très vite initiée à la musique par une mère, chanteuse de world, par un père passionné de guitare et un frère compositeur. A l’âge de 12 ans, elle apprend à jouer du piano et de la guitare de façon autodidacte en reprenant tour à tour les titres de Stevie Wonder, Céline Dion, Ray Charles et surtout Michael Jackson qu’elle prend comme modèle. Passionnée par les domaines artistiques, elle enchaîne les cours de danse, de théâtre et de chant pendant toute son adolescence. C’est à cette époque qu’elle commence à travailler pendant des mois pour définir son orientation artistique et se créer un répertoire.
			</p>
			<p className="reference">Quelle: © Ernst Klett Verlag GmbH, Stuttgart 2020 und Website https://www.tal-officiel.com/Biographie</p>
		</div>
	)
}

export default AstTalText
