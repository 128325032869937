import React from 'react'
import { Carousel } from 'react-responsive-carousel'
// eslint-disable-next-line no-unused-vars
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css'


function AstTaschePacken() {
	return(
		<div className="panel-body">
			<Carousel showArrows={true} showThumbs={false} showStatus={false} swipeable={true} emulateTouch={true} autoPlay={true} interval={4500} stopOnHover={true} className="klett-carousel">
				<div>
					<img src="./assets/images/Schulranzen_1.jpg" alt="Schultasche packen 1" />
				</div>
				<div>
					<img src="./assets/images/Schulranzen_2.jpg" alt="Schultasche packen 2" />
				</div>
				<div>
					<img src="./assets/images/Schulranzen_3.jpg" alt="Schultasche packen 3" />
				</div>
				<div>
					<img src="./assets/images/Schulranzen_4.jpg" alt="Schultasche packen 4" />
				</div>
				<div>
					<img src="./assets/images/Schulranzen_5.jpg" alt="Schultasche packen 5" />
				</div>
			</Carousel>
			<p className="reference">Screenshots aus dem Film „Chez les Bertucat, on cherche et on trouve!“: © Ernst Klett Verlag GmbH, Stuttgart 2020; Produktion: Tevau GmbH, Mannheim</p>
		</div>
	)
}

export default AstTaschePacken
