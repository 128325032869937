import React from 'react'
import AudioPlayer from '../components/AudioPlayer'


function AstBuecherRegal() {
	return(
		<div className="panel-body">
			<h1>J’aime les livres</h1>
			<img src="./assets/images/Lola_Buch.jpg" className="img-responsive" alt="Lola am Bücherregal" />
			<AudioPlayer url='./assets/audio/Lola_Buch.mp3' />
			<p className="reference">Quelle: © Ernst Klett Verlag GmbH, Stuttgart 2020, Fotograf: Johannes Killinger</p>
		</div>
	)
}

export default AstBuecherRegal
