import React from 'react'
import AudioPlayer from '../components/AudioPlayer'


function AstMaxZimmer() {
	return(
		<div className="panel-body">
			<h1>Max</h1>
			<img src="./assets/images/Foto_Max.jpg" className="img-responsive" alt="Lola am Bücherregal" />
			<AudioPlayer url='./assets/audio/Max_audio.mp3' />
			<p className="reference">Quelle: © Ernst Klett Verlag GmbH, Stuttgart 2020, Fotografin: Christelle Souvras</p>
		</div>
	)
}

export default AstMaxZimmer
